import { z } from "zod";

import { User, UserSchema } from "./api-schemas";
import { http } from "./http";

export async function updateCurrentUser(updatedUser: User) {
  const { data } = await http.put({
    path: "current_user",
    body: { user: updatedUser },
    schema: z.object({ user: UserSchema }),
    noTenantId: true,
  });

  return data.user;
}
